/** @format */

import React, { useEffect, useState } from "react";
import unmintCardImg from "../../../../../assets/images/card/marketInfo.png";
import cardImg from "../../../../../assets/images/teleImage/brgCard.png";
import MintCard_P from "../../../../../assets/images/card/MintCard_P.png";
import marketImg from "../../../../../assets/images/card/marketImg.png";
import aptosLogo from "../../../../../assets/images/card/aptosLogo.png";
import style from "./cardStyle.module.scss";
import CollectionsCard from "./collectionsCard";
import Countdown from "react-countdown";
import {
	getCollectionList,
	getInfoCardMinted,
	getListCardReadyToMint,
	getMarkePlace,
	requestListCardSyncData,
	requestMinCard,
	requestSubmitMinCard,
	requestUpdateStatusMintCard,
} from "../../../../../services/accountApiService";
import {
	CardMinted,
	CardWateMintItemModel,
} from "../../../../../commonsUi/modals/Card/cardWaitMint";
import { encodeJwt } from "../../../../../utils/extension";
import CardMintedModal from "../../../../../commonsUi/modals/CardMintedModal";
import { devnetClient } from "../../../../../core/constants";
import {
	feePayer,
	getWalletFromLocalStorage,
} from "../../../../../utils/aptos/aptosStorage";
import {
	getUserOwnedSlimeNFT,
	MODULE_ADDRESS,
} from "../../../../../aptosConfig";
import LoadingCommon from "../../../../../commonsUi/CommonUI/LoadingApp";
import ModalBurnCard, {
	BuildProgress,
} from "../../../../../commonsUi/modals/ModalBurnCard";
import CardBeforeMintModal from "../../../../../commonsUi/modals/CardInforBeforeMint";
import ModalSlime from "../../../../../commonsUi/modals/Modal";
import SavePrivateKeyModal from "../../../../../commonsUi/modals/SavePrivateKeyModal";

const CardPage: React.FC = React.memo(() => {
	const [cardMint, setCardMint] = useState<CardWateMintItemModel[] | null>(
		null
	);
	const [isBought, setBought] = useState(false);
	const [isShowConnectWallet, setConnectWallet] = useState(false);
	const [cardMinted, setCardMinted] = useState<CardMinted | null>(null);
	const [isShowCardMinted, setShowCardMinted] = useState(false);
	const [isShowLoading, setShowLoading] = useState(false);
	const [onShowDialogMintCard, SetShowDialogMintCard] = useState(false);
	const [idCollectionShow, setIdCollectionShow] = useState(false);
	const [isShowSavePrivateKey, setShowPrivateKeyFirst] = useState(false);
	const [cardMintedInfo, setCardMintedInfo] = useState<MarketInfo | null>(
		null
	);
	const [listCardSync, setListCardSync] = useState<number[] | null>(null);
	const [collectionList, setCollectionList] = useState<
		CollectionModel[] | null
	>(null);
	const [isShowAlert, setShowAlert] = useState(false);
	const [isShowAler1t, setShowAlert1] = useState("");
	const [isShowLoadingSync, setShowLoadingSync] = useState(false);
	const [countSync, setCountSync] = useState(0);

	const [listNft, setListNft] = useState<MarketResponse | null>(null);

	const requestGetAllCollection = async () => {
		const rest: any = await getCollectionList();
		console.log({ rest });
		setCollectionList(rest.data);
		console.log({ collectionList });
	};
	function stringToVectorU8(str: string): number[] {
		return Array.from(str).map((char) => char.charCodeAt(0));
	}
	const syncCardFun = async () => {
		for (let i = 0; i < listCardSync!.length; i++) {
			await mintCardFunc(listCardSync![i], true);
			setCountSync(i + 1);
		}
		setShowLoadingSync(false);
		getListCardSyncData();
		requestGetAllCollection();
	};
	const getListCardSyncData = async () => {
		const cardSync: any = await requestListCardSyncData();
		console.log({ cardSync });
		setListCardSync(cardSync.data);
		console.log({ listCardSync });
	};

	const updateStatusSyncCard = async (cardId: number, tokenId: string) => {
		const value = {
			card_id: cardId,
			token_id: tokenId,
		};
		const payLoadData = await encodeJwt(value);

		const cardSync = await requestUpdateStatusMintCard({
			value: payLoadData,
		});
		console.log({ cardSync });
	};

	useEffect(() => {
		getListCardSyncData();
	}, []);

	useEffect(() => {
		const isSavePrivateKey = localStorage.getItem("isSavePrivatekEY");
		console.log({isSavePrivateKey})
		if (!isSavePrivateKey) {
			setShowPrivateKeyFirst(true);
		}
	}, []);

	useEffect(() => {
		requestGetAllCollection();
	}, [isShowCardMinted]);

	useEffect(() => {
		const timer = setTimeout(() => {
			setShowAlert(false);
		}, 2000);
		return () => clearTimeout(timer);
	}, [isShowAlert]);
	useEffect(() => {
		const timer = setTimeout(() => {
			setShowAlert1("");
		}, 7000);
		return () => clearTimeout(timer);
	}, [isShowAler1t]);

	const mintCard = async (
		img: string,
		cardType: number,
		cardName: string,
		id: number,
		isSync: boolean
	) => {
		const signature = process.env.REACT_APP_SIGNATURE;
		const message = process.env.REACT_APP_MESSAGE;
		const signatureArray = signature?.split(",").map(Number);
		const messageArray = message?.split(",").map(Number);
		try {
			const uri = stringToVectorU8(img);
			const transaction = await devnetClient.transaction.build.simple({
				withFeePayer: true,
				sender: getWalletFromLocalStorage()!.accountAddress,
				data: {
					function: `${MODULE_ADDRESS}::slime_nft_slime::create_aptoslime`,
					functionArguments: [
						cardName,
						cardType,
						signatureArray,
						messageArray,
						uri,
						MODULE_ADDRESS,
					],
				},
			});
			const feePayerAuthenticator =
				devnetClient.transaction.signAsFeePayer({
					signer: feePayer,
					transaction,
				});

			const senderAuthenticator = devnetClient.transaction.sign({
				signer: getWalletFromLocalStorage()!,
				transaction,
			});
			const committedTransaction =
				await devnetClient.transaction.submit.simple({
					transaction,
					senderAuthenticator: senderAuthenticator!,
					feePayerAuthenticator: feePayerAuthenticator,
				});
			const response = await devnetClient.waitForTransaction({
				transactionHash: committedTransaction.hash,
			});

			const cardMinted = await getUserOwnedSlimeNFT();

			if (!isSync) {
				const value = {
					card_id: cardType,
					id_card_mint: id,
					token_id: cardMinted.token_data_id,
					transaction_hash: response.hash,
				};
				const payLoadData = await encodeJwt(value);
				await requestSubmitMinCard({ value: payLoadData });
				const dataMinted: any = await getInfoCardMinted(cardType);
				setCardMintedInfo(dataMinted.data);
				console.log({ dataMinted });
				setShowLoading(false);
				console.log({ response });
				setShowCardMinted(true);
				setTimeout(() => {
					setBought(true);
					requestGetListCollection();
				}, 2000);
			} else {
				await updateStatusSyncCard(cardType, cardMinted.token_data_id);
				setShowLoading(false);
			}
		} catch (e: any) {
			setShowLoading(false);
			setShowLoadingSync(false);
			if (
				e?.message === "EphemeralKeyPair is expired" ||
				e?.message ===
					"Cannot read properties of undefined (reading 'accountAddress')"
			) {
				// setShowAlert1(e?.message);
				// setConnectWallet(true);
			}
			setShowAlert1(e?.transaction.vm_status);
			console.log({ e });
		}
	};

	const requestGetListCollection = async () => {
		const res: any = await getListCardReadyToMint();
		const data = res.data?.map((card: any) => ({
			...card,
			CanMint: true,
		}));
		console.log({ res });
		setCardMint(data);
	};

	const changeStatusMint = (id: number) => {
		setCardMint((prevCards) =>
			prevCards!.map((card) =>
				card.Id === id ? { ...card, CanMint: false } : card
			)
		);
	};

	const mintCardFunc = async (idCard: number, isSync: boolean) => {
		console.log({ isSync });
		try {
			if (!isSync) {
				setShowLoading(true);
			} else {
				setShowLoadingSync(true);
			}
			const value = {
				card_id: idCard,
			};
			const value1 = {
				card_id: idCard,
				type: 1,
			};
			const payLoadData = await encodeJwt(isSync ? value1 : value);
			const res: any = await requestMinCard({ value: payLoadData });
			console.log({ res });
			const mintedCard: CardMinted = {
				img: res.data.image_link,
				name: res.data.card_name,
			};
			if (!isSync) {
				setCardMinted(mintedCard);
			}
			await mintCard(
				res.data.image_link,
				res.data.type,
				res.data.card_name,
				res.data.id,
				isSync
			);
		} catch (e) {
			setShowLoading(false);
			setShowLoadingSync(false);
		}
	};

	const StatsCard = () => {
		return (
			<div className={style.statsCard}>
				<div className={style.statsItem}>
					<span>Total Volume:</span>
					<span>
						<strong>{`${
							Number(cardMintedInfo?.total_volume).toFixed(2) ??
							""
						} APT`}</strong>
					</span>
				</div>
				<div className={style.statsItem}>
					<span>Floor Price:</span>
					<span>
						<strong>{`${Number(
							cardMintedInfo?.floor_price.toFixed(2)
						)} APT`}</strong>
					</span>
				</div>
				<div className={style.statsItem}>
					<span>Last sold:</span>
					<span>
						<strong>{`${Number(
							cardMintedInfo?.last_sold.toFixed(2)
						)} APT`}</strong>
					</span>
				</div>
				<div className={style.statsItem}>
					<span>Highest Price:</span>
					<span>
						<strong>{`${Number(cardMintedInfo?.highest).toFixed(
							2
						)} APT`}</strong>
					</span>
				</div>
				<div className={style.statsItem}>
					<span>Listed:</span>
					<span>
						<strong>{`${Number(
							cardMintedInfo?.listed.toFixed(2)
						)} %`}</strong>
					</span>
				</div>
				<div className={style.statsItem}>
					<span>Unique Owner:</span>
					<span>
						<strong>{`${cardMintedInfo?.unique_own} `}</strong>
					</span>
				</div>
			</div>
		);
	};

	const RenderMintCard = () => {
		return (
			<div className={style.mintcardBody}>
				<div
					className={`${style.mintCardM} active-btn`}
					onClick={() => {
						if (cardMint![0]!.CanMint) {
							SetShowDialogMintCard(true);
						}
					}}
				>
					<img
						src={MintCard_P}
						alt=""
						className={style.mintCardImg}
					/>
					<Countdown
						className={style.mintCardText}
						date={Number(
							new Date(cardMint![0].ExpiredTimeMint).getTime()
						)}
						onComplete={() => {
							if (!cardMint![0].CanMint) {
								changeStatusMint(cardMint![0].Id);
								requestGetListCollection();
								return;
							}
						}}
					/>
					<p className={style.cardValue}>{`${cardMint?.length}/5`}</p>
				</div>
			</div>
		);
	};

	const requestMarketPlace = async () => {
		const res: any = await getMarkePlace(1);
		setListNft(res);
		console.log(res);
	};

	useEffect(() => {
		requestMarketPlace();
		requestGetListCollection();
	}, [isShowLoading]);

	useEffect(() => {
		const timer = setTimeout(() => {
			setBought(false);
		}, 2000);
		return () => clearTimeout(timer);
	}, [isBought]);

	const RenderHeader = () => {
		return (
			<div className={style.main1}>
				<div className={style.headerStyle}>
					<img
						className={style.cardStyle}
						src={cardImg}
						alt="cardImg"
					/>
					<img
						className={style.tagCardImg}
						src={unmintCardImg}
						alt="cardImg"
					/>
					<div className={style.bodyHeader}>
						<div
							style={{
								display: "flex",
								width: "100%",
								justifyContent: "space-between",
								padding: "0 16px",
								alignItems: "center",
								alignContent: "center",
								marginTop: "20px",
							}}
						>
							<div>
								<div className={style.statsItem}>
									<span className={style.label}>Volume:</span>
									<span className={style.value}>
										<strong>{`${listNft?.data.summary_market.total_volume.toFixed(
											2
										)} APT`}</strong>
									</span>
								</div>
								<div className={style.statsItem}>
									<span className={style.label}>
										Total Supply:
									</span>
									<span className={style.value}>
										<strong>{`${listNft?.data.summary_market.total_supply}`}</strong>
									</span>
								</div>
								<div className={style.statsItem}>
									<span className={style.label}>
										Unique Owner:
									</span>
									<span className={style.value}>
										<strong>{`${listNft?.data.summary_market.unique_own}`}</strong>
									</span>
								</div>
							</div>
							<img
								src={marketImg}
								className="active-btn"
								alt="marketImg"
								width={100}
								onClick={() => {
									setShowAlert(true);
								}}
								height={100}
							/>
						</div>
						<img
							src={aptosLogo}
							alt="aptosLogo"
							height={20}
							style={{
								marginLeft: "95px",
								marginTop: "20px",
							}}
							width={160}
						/>
					</div>
				</div>
			</div>
		);
	};
	return (
		<div className={style.main2}>
			<div className={style.main}>
				{isShowCardMinted && (
					<CardMintedModal
						count={cardMintedInfo!.star}
						img={cardMinted!.img}
						onMinted={() => {
							setShowCardMinted(false);
							setIdCollectionShow(true);
						}}
						onClose={() => {
							setShowCardMinted(false);
						}}
					>
						<StatsCard />
					</CardMintedModal>
				)}
				{isShowAlert && (
					<div className={style.notice1}>{`Coming soon`}</div>
				)}
				{isShowAler1t !== "" && (
					<div className={style.notice3}>
						{`The minting feature is currently undergoing maintenance. Please visit us again later. Rest assured, your unminted cards are secure.
`}
					</div>
				)}

				<RenderHeader />
				{collectionList &&
					collectionList?.length > 0 &&
					listCardSync !== null && (
						<CollectionsCard
							isShowSyncCard={
								listCardSync !== null && listCardSync.length > 0
							}
							onTapSyncCard={() => {
								syncCardFun();
							}}
							collectionList={collectionList!}
						/>
					)}
			</div>

			<LoadingCommon
				visible={isShowLoading}
				width={80}
				height={80}
				isLeft10={true}
			/>
			{isShowCardMinted && isShowSavePrivateKey && (
				<SavePrivateKeyModal
					onClose={() => {
						setShowPrivateKeyFirst(false);
						localStorage.setItem("isSavePrivatekEY", "1");
					}}
				/>
			)}
			{idCollectionShow && (
				<ModalBurnCard
					idCardSelect={cardMintedInfo!.collection_id}
					onClose={() => {
						setIdCollectionShow(false);
						requestGetAllCollection();
					}}
				/>
			)}
			{cardMint && cardMint.length > 0 && <RenderMintCard />}
			{onShowDialogMintCard && (
				<CardBeforeMintModal
					onConnectWallet={() => {
						SetShowDialogMintCard(false);
						setConnectWallet(true);
					}}
					onClaim={() => {
						SetShowDialogMintCard(false);
						mintCardFunc(cardMint![0].CardId, false);
					}}
					cardLevel={cardMint![0].LevelCard}
					onClose={() => SetShowDialogMintCard(false)}
					ExpiredTimeMint={cardMint![0].ExpiredTimeMint}
				/>
			)}
			<ModalSlime
				isOpen={isShowLoadingSync}
				onClose={() => {
					setShowLoadingSync(false);
				}}
				title="Sync your cards is in progress: "
			>
				<BuildProgress
					current={countSync}
					total={listCardSync ? listCardSync!.length : 0}
				/>
			</ModalSlime>
		</div>
	);
});
export default CardPage;
